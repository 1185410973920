@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;





/* ---- Custom Styles -------*/


.row .row {margin-left:0;margin-right:0;}
.icons {margin-bottom:14px;}
.pdiv {margin-bottom:18px; font-size:18px; line-height:23px;}

#logo {width:90px; padding-top:7px;}

	.top-bar {
		height:60px;
		padding:0 5%;

		.menu a {
			height:60px;
			color:$white;
			font-family: "bebas-neue", sans-serif;
			font-size:22px;
			letter-spacing: 0.01em;
			font-weight: 500;
			line-height:30px;
		}

		.menu a:hover {
			color:$gold;
		}
	}

.off-menu {list-style:none; margin-left:0; width:100%;  float:left;}
.off-menu a {
	display:block;
	padding:14px 0 14px 24px; 
	border-bottom: solid 1px #222; 
	width:100%; 
	height:48px; 
	font-weight:bold;
	color:#fff;
	background-color:#555;
}

#offCanvasRight {height:100vh;}

.green {background-color:green;}
	.menu-icon {float:right; margin-top:22px;}

.hero-wrap {
	    position: relative;
    height: 90vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    text-align: center;
    		background-image:url(../img/aoc-cover2.jpg);
		background-position: center;
		-webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.schedule {
	position:absolute;
	top:8%;
	left:36%;
	font-family:"bebas-neue", sans-serif;
	padding:6px 12px;
	border: solid 2px #b8996e;
	font-size:20px;
	color:#000;
}

.cut, .color, .style {color:#fff; height:100%; padding:10px 32px;}
.cut {background-color:#141414;}
.color {
	background-color:#292929;
}
.style {background-color:#141414;}



	.main-stripe {
		text-align:center; 
		padding:20px 0 14px;

		p {}

	}

	h1 {
		font-family: "bebas-neue", sans-serif;
		font-weight: 500;
		font-size: 34px;
		text-transform: uppercase;
		letter-spacing: 0.02em;
		margin:0 10px 14px;

	}

	h2 {
		font-weight:bold;
		font-style: italic;
		font-size: 26px;
		letter-spacing: 0.05em;
		padding:10px 0 14px;
		margin-bottom: 16px;
		border-bottom: solid 1px #555;
	}

	h3 {
		font-family: "bebas-neue",sans-serif;
		font-style: normal;
		font-weight: 600;
		color:$gold;
		margin-bottom: 0;
		padding-bottom:0;
		
	}


	.icon-shopping {
		display: inline-block;
		height:30px;
		padding:0 20px;
	}
	.icon-dining {
		display: inline-block;
		height:30px;
		padding:0 20px;
	}


	.come-home {
		float: left;
		margin:0 auto;
		background-color:#f7f5eb;
		width: 100%;
		margin-top: -20px;
		z-index: -1;
	}
	.video-tour {
		background-image: url(../img/wall-texture.jpg);
		background-size:100vw;

		h1, p {color:$white;}

	}

	.amenities {
		background-image: url(../img/pool-water.jpg);
		background-position: center;
		background-size:200vw;
		h1, p {color:$white;}
	}

	.icon-clubhouse {margin:0 0 10px;
		background-image: url(../img/icon-clubhouse.svg);
		background-position:center;
		background-repeat:no-repeat;
		background-size: 120px 48px;
		width: 36%; margin-left: 14%; height:50px; float:left;}

	.icon-pool {margin:0 0 10px;
		background-image: url(../img/icon-pool.svg);
		background-position:center;
		background-repeat:no-repeat;
		background-size: 100px 48px;
		width: 36%; height:50px; float:left;}

	.garden-plan, .ranch-plan {float:left; margin:0 18% 22px; width:64%;}


	.finishings {
		background-image: url(../img/wood-floor.jpg);
		background-position:center;
		background-size:190vw;
		h1, p {color:$white;}

		.icon {padding:0 10px; width:100%; height:50px; float:left;}
		.finishings-icons-wrap {margin:0 auto; width:70%;}
		.finishing-icon-div {width:33.3%; float:left;}

	}

	.CTA-button {
		float: left;
		font-family: "bebas-neue",sans-serif;
		width:90%;
		font-weight: 500;
		letter-spacing: 0.05em;
		font-size: 20px;
		padding:6px 0;
		color:$black;
		margin:10px 20% 12px;
		border: solid 2px #b8996e;
	}

	.CTA-email {margin-bottom:26px;}

	.background-white {background-color:#fff; padding-top:10px;
		a {margin-left:10px;}
		ul {margin-left:30px;}
	}

	.bios {

		.center {text-align:center; border-bottom:solid 1px #333; margin-bottom:16px;}
		h2 {
			font-family: "bebas-neue",sans-serif;
			font-style: normal;
			letter-spacing: 0.1em;
			border:none;
			padding-bottom:0;
			}

		.portrait {
			border-radius:50%;
			width: 130px;
			height: 130px;
			margin-top: 16px;
		}
		.gray {color:#555;}
	}

	.plan-container {
		width:280px; 
		margin:10px auto 24px; 
		background-color:#404547;
		border-radius:5px;
		padding:8px 8px;
	}
	.plan-number {color:#fff; font-size:28px; padding-bottom:8px;}
	.plan-price {
		font-family: "bebas-neue",sans-serif;
		font-style: normal;
		font-weight: 600;
		color:$gold;
		font-size: 34px;
		margin-top: 8px;
	}

	.contact-box {margin-top:10px;
		h2, p {text-align:center;}
	}

	.contact-box-map {margin:0 auto; 
		p {text-align:center;}
		iframe {margin:4px auto 34px; width:100%; height:300px;}}
	.contact-location {text-align:center; margin-top:20px;}


	.pricing-1, .pricing-2 {
		table-layout:fixed;
		width:100%;
		border: solid 1px #222;
		box-shadow:0 0 1px #000;
	}

	.row-services {width:72%; font-family: "bebas-neue", sans-serif; font-size:18px; letter-spacing:0.1em; text-align:right;}
	.row-price {width:28%; font-family: "bebas-neue", sans-serif; font-size:18px; letter-spacing:0.1em;
}

	tr td:nth-child(1) { 
	   text-align: right;
	}
		tr td:nth-child(2) { 
	   text-align: left;
	}

.gold {background-color:#f7f5eb;}
	
	footer {
		width: 100%;
		height: 62px;
		background-color:$black;
		color:white;
		text-align:center;
		line-height: 58px;
		font-size: 13px;
	}

.notReal {display:none; width:0; height:0;}

@import 'components/media-queries'; 


