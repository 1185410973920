// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

@mixin foundation-text-alignment {
  @each $align in (left, right, center, justify) {
    .text-#{$align} {
      text-align: $align;
    }

    @each $size in $breakpoint-classes {
      @include breakpoint($size) {
        @if $size != 'small' {
          .#{$size}-text-#{$align} {
            text-align: $align;
          }
        }
      }
    }
  }
}
