
      /* Medium and up */
      @media screen and (min-width: 40em) {

  	.bios {




		.portrait {
			width: 160px;
			height: 160px;
			margin-top: 20px;
		}
	}

		.main-stripe {
			padding:30px 0 24px;
		}

	.garden-plan {margin:0 0 22px;  width:46%;}
	.ranch-plan {margin:0 0 22px;  width:46%;}


	.CTA-button {
		width:44%;
		letter-spacing: 0.08em;
		margin:14px 3% 26px;
	}

		
		.icons {margin-bottom:14px;}

		footer {
			font-size: 16px;
		}

      }
      
      /* Medium only */
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      
      	#logo {width:auto;}

			.top-bar {
				height:80px;
				padding:0 4%;

				.menu a {
					height:80px;
					line-height:60px;
				}
			}

				.schedule {
					top:12%;
					left:40%;
					padding:8px 16px;
					font-size:24px;
				}

			.come-home {margin-top: -32px;}


			h1 {

				letter-spacing: 0.10em;
				margin:0 10px 20px;
			}

			.pricing-1, .pricing-2 {
				width: 44%; 
				float:left;
				margin-right:2%;
				margin-left:2%;
			}

			.pricing-1 {margin-left: 4%;}

      	.icon-shopping, .icon-dining {
				height:40px;
				padding:0 25px;
			}

		.amenities {background-size:150vw;}

		.icon-clubhouse {margin:0 0 12px;
			background-size: 150px 58px;
			width: 36%; margin-left: 14%; height:60px;}

		.icon-pool {margin:0 0 12px;
			background-size: 120px 58px;
			width: 36%; height:60px;}

		.finishings {
			background-size:160vw;

			.icon {padding:0 10px;  height:56px;}
			.finishings-icons-wrap {margin:0 auto; width:48%;}

		}

		.plan-wrap {width:600px; margin: 0 auto;}

		.plan-container {
			width:280px; 
			margin:10px 10px 24px; 
			float:left;
		}
		.plan-number {font-size:28px; padding-bottom:8px;}
		.plan-price {
			font-size: 34px;
			margin-top: 8px;
		}

		.contact-box {margin-top:10px; float:left !important;}
		.contact-box-map {
			iframe {margin:4px auto 34px; width:100%; height:380px;}
		}

      }
      
      /* Large and up */
      @media screen and (min-width: 64em) {

      	#logo {width:auto;}

      	.top-bar {
			height:80px;
			padding:0 7%;

		.menu a {
			height:80px;
			font-weight: 500;
			line-height:60px;
		}
	}

				.schedule {
					top:12%;
					left:43%;
					padding:8px 16px;
					font-size:24px;
				}

			.main-stripe {
				padding:40px 0 34px;
			}

			h3 {
				font-weight: 600;
				margin-bottom: 0;
				padding-bottom:0;
				
			}

			h1 {

				letter-spacing: 0.14em;
				margin:0 10px 30px;
			}

			.pricing-1, .pricing-2 {
				width: 34%; 
				float:left;
				margin-right:1%;
				margin-left:1%;
			}

			.pricing-1 {margin-left: 15%;}
				
			.icon-shopping, .icon-dining {
			
				height:50px;
				padding:0 30px;
			}

			.amenities {background-size:106vw;}

			.icon-clubhouse {margin:0 0 12px;
				background-size: 200px 75px;
				width: 36%; margin-left: 14%; height:77px;}

			.icon-pool {margin:0 0 12px;
				background-size: 157px 75px;
				width: 36%; height:77px;}

			.finishings {
				background-size:128vw;

				.icon {padding:0 10px; margin-top:10px; height:60px;}
				.finishings-icons-wrap {margin:0 auto; width:38%;}

			}

			.CTA-button {
				width:44%;
				letter-spacing: 0.08em;
				margin:14px 3% 26px;
			}

		.plan-wrap {width:720px; margin: 0 auto;}

		.plan-container {
			width:280px; 
			margin:10px 38px 24px; 
			float:left;
		}
		.plan-number {font-size:28px; padding-bottom:8px;}
		.plan-price {
			font-size: 34px;
			margin-top: 8px;
		}

		.contact-box {margin-top:10px; float:left !important;}
		.contact-box-map {
			iframe {margin:4px auto 34px; width:100%; height:500px;}
		}

		.notReal{display:block;}

	}
	     


