// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group badge
////

/// Default background color for badges.
/// @type Color
$badge-background: $primary-color !default;

/// Default text color for badges.
/// @type Color
$badge-color: foreground($badge-background) !default;

/// Default padding inside badges.
/// @type Number
$badge-padding: 0.3em !default;

/// Minimum width of a badge.
/// @type Number
$badge-minwidth: 2.1em !default;

/// Default font size for badges.
/// @type Number
$badge-font-size: 0.6rem !default;

/// Generates the base styles for a badge.
@mixin badge {
  display: inline-block;
  padding: $badge-padding;
  min-width: $badge-minwidth;
  font-size: $badge-font-size;
  text-align: center;
  border-radius: 50%;
}

@mixin foundation-badge {
  .badge {
    @include badge;

    background: $badge-background;
    color: $badge-color;

    @each $name, $color in $foundation-colors {
      @if $name != primary {
        &.#{$name} {
          background: $color;
          color: foreground($color);
        }
      }
    }
  }
}
